// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { SignupForm } from "../components/convertkit-signup"


/*
<main class="px-3">
  <h1>Cover your page.</h1>
  <p class="lead">Cover is a one-page template for building simple and beautiful home pages. Download, edit the text, and add your own fullscreen background photo to make it your own.</p>
  <p class="lead">
    <a href="#" class="btn btn-lg btn-secondary fw-bold border-white bg-white">Learn more</a>
  </p>
</main>
*/


type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({ data, path }) => (
  <Layout className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <SEO title="Cereb.io" />
    <main className="px-3">
      <h1>Your Programmable Brain.</h1>
      <p className="lead">
        Cereb.io is an API-first to-do list, designed for Software Developers.
      </p>
      <h2 className="pt-5">Alpha Opening October 2021.</h2>
      <div className="py-5" dangerouslySetInnerHTML={{__html: SignupForm}}></div>
    </main>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
